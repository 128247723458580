import React from "react";
import PropTypes from "prop-types";
import defaultImage from "./image/default-web.png";

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

class Web extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const urlImage = this.props.img && this.props.img.url;
    return (
      <div className="card-web">
        <img
          src={urlImage || defaultImage}
          alt={this.props.title || ""}
          referrerPolicy="no-referrer"
        />
        <div className="con-text">
          <h2>{this.props.title || ""}</h2>
          <p>
            {this.props.description || (
              <>
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            <br />
            <button onClick={() => openInNewTab(this.props.link.url)}>
              zobrazit web
            </button>
          </p>
        </div>
      </div>
    );
  };
};

Web.propTypes = {
  ida: PropTypes.number.isRequired,
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
};

export default Web;

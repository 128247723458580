import { useState, useEffect, useCallback } from "react";

export const useFetch = (url) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [errors, setError] = useState({});
  const getProducts = useCallback(async () => {
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          setError({ error: "Error code: " + res.status });
          setLoading(false);
        } else {
          setProducts(res.data);
          setLoading(false);
          setError(res.errorMap);
        }
      })
      .catch((err) => {
        setError({ error: "" + err.message });
      });
  }, [url]);

  useEffect(() => {
    getProducts();
  }, [url, getProducts]);
  return { loading, products, errors };
};

import React from "react";
import { Link } from "react-router-dom";

class Page404 extends React.Component {
  constructor(props) {
    super(props);

    document.title = "Page not found - Patrik Sekerka";
  }

  render() {
    return (
      <div className="main-area">
        <section className="left-section  full-height">
          <div className="display-table">
            <div className="display-table-cell">
              <div className="error404-content">
                <h1 className="title">
                  <b>ERROR 404</b>
                </h1>
                <h3>Page not found</h3>
                <p className="desc">
                  We are sorry but the page you are looking for does not exist.
                  <br />
                  Please go to the{" "}
                  <Link to="/" className="linky">
                    homepage
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="right-section full-height"
          style={{
            backgroundColor: "#fff",
            backgroundImage:
              "url(https://www.sekerka.cz/images/logo-1000x1000.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></section>
      </div>
    );
  }
}

export default Page404;

import React from "react";

const Header = () => {
  return (
    <div className="main-area">
      <section
        className="left-section"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url(https://www.sekerka.cz/images/logo-1000x1000.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      ></section>

      <section className="right-section full-height">
        <div className="display-table">
          <div className="display-table-cell">
            <div className="main-content">
              <h1 className="title">
                <b>Be the change you want to see in the world</b>
              </h1>
              <p className="desc">My website ...</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;

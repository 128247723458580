import React from "react";

import Loading from "./loading/loading";
import WebSite from "./webSite";
import Foother from "./foother";

function wApp() {
  document.title = "Web applications - Patrik Sekerka";
  if (navigator.onLine) {
    return (
      <>
        <div className="main-area-second">
          <div class="main-area-text">
            <h1>Applications</h1>
          </div>
        </div>
        <div className="App">
          <div className="sectionLight">
            <WebSite />
          </div>
        </div>
        <Foother />
      </>
    );
  } else {
    return <Loading load="home" />;
  }
}

export default wApp;

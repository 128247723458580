import React from "react";
import PropTypes from "prop-types";

import loadingImage from "../image/patrik-logo.webp";

const Loading = ({ load }) => {
  const stylable = load === "home" ? "sectionLoadingHome" : "sectionLoading";
  return (
    <div className={stylable}>
      <div className="img-wrapper-loading">
        <img src={loadingImage} alt="" />
      </div>
    </div>
  );
};
Loading.propTypes = {
  load: PropTypes.string.isRequired,
};
export default Loading;

import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./component/navbar"
import Loading from "./loading/loading";
import Home from "./home";
import Page404 from "./page404";
import Wapp from "./wapp"
import MApp from "./mapp";


function App() {
  if (navigator.onLine) {
    return (
      <>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/m-app" element={<MApp />} />
          <Route path="/w-app" element={<Wapp />} />
          <Route path="" element={<Page404 />} />
          <Route path="*" element={<Page404 />} />
          <Route component={() => <Page404 />} />
        </Routes>
      </>
    );
  } else {
    return <Loading load="home" />;
  }
}

export default App;

import React from "react";
class Error extends React.Component {
  constructor({ error }) {
    super({error});
    this.error = error;
  }
  

  render() {
    return <p className="error">{this.error.error_description}</p>;
  }
}
export default Error;

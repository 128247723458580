import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.css";
import App from "./App";
import ScrollToTop from "./scrolltop";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log(
  "%cKUK!",
  "font: bold 4em sans-serif; -webkit-text-stroke: 1px black; color: red;"
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

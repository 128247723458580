import React from "react";
import PropTypes from "prop-types";
import defaultImage from "./image/default.jpg";

const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const Product = ({ title, description, img, android, apple, link }) => {
  const urlImage = img && img.url;
  return (
    <div className="card-app">
      <ul className="ul">
        {android ? (
          <li>
            <a href={android} target="_blank" rel="noopener noreferrer">
              <i className="bi bi-android2" style={{ color: "#3DDC84" }}></i>
            </a>
          </li>
        ) : null}
        {apple ? (
          <li>
            <a href={apple} target="_blank" rel="noopener noreferrer">
              <i className="bi bi-apple"></i>
            </a>
          </li>
        ) : null}
      </ul>
      <img
        src={urlImage || defaultImage}
        alt={title || ""}
        referrerPolicy="no-referrer"
      />
      <div className="con-text">
        <h2>{title || ""}</h2>
        <p>
          {description || ""}
          <br />
          <button onClick={() => openInNewTab(link.url)}>zobrazit web</button>
        </p>
      </div>
    </div>
  );
};

Product.propTypes = {
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  android: PropTypes.string.isRequired,
  apple: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
};

export default Product;

import React from "react";
import { useFetch } from "./useFetch";
import Web from "./web";
import Loading from "./loading/loading";
import Error from "./error";

const url = "https://www.sekerka.cz/api/website/";

const WebSite = () => {
  const { loading, products, errors } = useFetch(url);
  const load = () => {
    return <Loading load="component" />;
  };
  const getWebData = () => {
    return (
      <div>
        <h2 className="titleSection">Web applications</h2>

        <section className="products-web">
          {Object.keys(errors).length === 0 ? (
            products.map((product) => {
              return <Web key={product.ida} {...product} />;
            })
          ) : (
            <Error error={errors} />
          )}
        </section>
      </div>
    );
  };

  return loading ? load() : getWebData();
};

export default WebSite;

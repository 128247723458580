import React from "react";

const Foother = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="sectionLight">
      <hr />
      <footer className="site-footer">
        <p className="copyright-text">
          Copyright &copy; 2007 - {currentYear} All Rights Reserved by{" "}
          <a href="https://www.sekerka.cz" rel="noreferrer noopener">
            Patrik Sekerka
          </a>
          .
        </p>
        <div className="social-icons">
          <ul className="footer-icons">
            <li>
              <a
                href="https://www.instagram.com/spatrickeu/"
                rel="noreferrer noopener nofollow"
                title="Instagram"
                className="instagram"
              >
                <em className="bi bi-instagram"></em>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/patriksekerka"
                rel="noreferrer noopener nofollow"
                title="LinkedIn"
                className="linkedin"
              >
                <em className="bi bi-linkedin"></em>
              </a>
            </li>
            <li>
              <a
                href="https://vimeo.com/spatrickeu"
                rel="noreferrer noopener nofollow"
                title="Vimeo"
                className="vimeo"
              >
                <em className="bi bi-vimeo"></em>
              </a>
            </li>
            <li>
              <a
                href="https://apps.apple.com/cz/developer/patrik-sekerka/id1296392264"
                rel="noreferrer noopener nofollow"
                title="App Store"
                className="apple"
              >
                <em className="bi bi-apple"></em>
              </a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/dev?id=5795378110601819346"
                rel="noreferrer noopener nofollow"
                title="Google Play"
                className="android"
              >
                <em className="bi bi-android2"></em>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/spatrickeu"
                rel="noreferrer noopener nofollow"
                title="GitHub"
                className="github"
              >
                <em className="bi bi-github"></em>
              </a>
            </li>
            <li>
              <a
                href="skype:spatrickeu?call"
                rel="noreferrer noopener nofollow"
                title="Skype"
                className="skype"
              >
                <em className="bi bi-skype"></em>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Foother;

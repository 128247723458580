import React from "react";

import Loading from "./loading/loading";
import Header from "./header";
import Foother from "./foother";

function Home() {
    document.title = "Welcome - Patrik Sekerka";
  if (navigator.onLine) {
    return (
      <>
        <Header />
        <Foother />
      </>
    );
  } else {
    return <Loading load="home" />;
  }
}

export default Home;
